import splitbee from '@splitbee/web'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { addCircleToPublic, createCircleApi } from '../api'
import { generateID, SplitbeeEvents } from '../helper'

const initCircleObj = {
    id: '',
    title: '',
    enTitle: '',
    description: '',
    enDescription: '',
    goal: '',
    arabicText: '',
    turkishText: '',
    enTranslation: '',
    isLoop: true,
    numOfCompleted: 0,
}

const template = {
    tefriciye: {
        arabicText:
            'اللَّهُمَّ صَلِّ صَلَاةً كَامِلَةً ❁ وَسَلِّمْ سَلَامًا تَامًّا ❁ عَلَىٰ سَيِّدِنَا مُحَمَّدٍ الَّذِي تَنْحَلُّ بِهِ الْعُقَدُ ❁ وَتَنْفَرِجُ بِهِ الْكُرَبُ ❁ وَتُقْضَىٰ بِهِ الْحَوَائِجُ ❁ وَتُنَالُ بِهِ الرَّغَائِبُ وَحُسْنُ الْخَوَاتِمِ ❁ وَيُسْتَسْقَى الْغَمَامُ بِوَجْهِهِ الْكَرِيمِ ❁ وَعَلىٰ آلِهِ وَصَحْبِهِ ❁ فِي كُلِّ لَمْحَةٍ وَنَفَسٍ بِعَدَدِ كُلِّ مَعْلُومٍ لَكَ',
        turkishText:
            '<span style="display:block;margin-bottom:12px">Allâhumme salli salaten kâmileten ve sellim selamen tâmmen ala Seyyidinâ Muhammedinillezi tenhallü bihil ukadü ve tenfericu bihil-kürebü ve tukda bihil-havaicu ve tünalü bihir-reğâibü ve hüsnül-havatimi ve yustaskal ğamamu bivechihil Kerim ve ala âlihi ve sahbihi fi külli lemhatin ve nefesin bi adedi külli ma’lumin lek.</span> Allah’ım! Efendimiz Muhammed’e kâmil bir salât ile salât ve selâm eyle! O öyle bir zâttır ki, kendisiyle düğümler çözülür, sıkıntılar kolaylaşır, ihtiyaçlar hallolur, arzulara kavuşulur ve güzel sonuçlara ulaşılır. Kendisinin yüzü suyu hürmetine yağmur istenir. Her göz açıp kapamada ve her nefeste tarafından bilinen varlıklar sayısınca O’nun âline ve ashâbına da salât ve selâm olsun',
    },
    sifa: {
        arabicText:
            'اَللَّهُمَّ صَلِّ عَلَى سَيِّدِنَا مُحَمَّدٍ طِبِّ الْقُلُوْبِ وَدَوَائِهَا وَعَافِيَةِ اْلأَبْدَانِ وَشِفَائِهَا ونُورِ اْلأَبْصَارِ وَضِيَائِهَا وَعَلَى آلِهِ وَصَحْبِهِ وَسَلِّم',
        turkishText:
            "Ey Allah’ım ! Kalblerin tabîbi ve devası, vücutların afiyeti ve şifâsı, gözlerin nûru ve ziyâsı olan Muhammed'e, âline ve ashâbına salât ve selâm eyle.",
    },
    salavat: {
        arabicText: 'اَللَّهُمَّ صَلِّ عَلَى سَيِّدِنَا مُحَمَّدٍ',
        turkishText:
            "Allâhumme salli alâ seyyidinâ Muhammed. Allah'ım! Sen Peygamber Efendimize ve onun soyundan gelenlere salat eyle",
    },
    tevbe: {
        arabicText: 'أَسْتَغْفِرُ اللَّهَ الْعَظِيمَ',
        turkishText:
            "Estağfurullah el aziym “Şânı pek yüce olan Allah'tan bağışlanmamı diliyorum.”",
    },
    kuranhatim: {
        arabicText: '',
        turkishText:
            '',
    },
}

function CreateCircle({ isAdminView = false, isMobile = false }) {
    const [circle, setCircle] = useState(initCircleObj)
    const [circleType, setCircleType] = useState('')
    const [quranType, setQuranType] = useState('cuz')
    const navigate = useNavigate()

    const { t } = useTranslation()

    const handleDuaSelectorChange = (event) => {
        setCircleType(event.target.value)
        if (event.target.value === 'kuranhatim') {
            setCircle({
                ...circle,
                "goal": "30"
            })
        }
    }

    const handleQuranTypeChange = (event) => {
        setQuranType(event.target.value)
        if (event.target.value === 'cuz') {
            setCircle({
                ...circle,
                "goal": "30"
            })
        } else if (event.target.value === 'sayfa') {
            setCircle({
                ...circle,
                "goal": "604"
            })
        }
    }

    useEffect(() => {
        if (!circleType) return

        if (circleType !== 'custom') {
            setCircle({
                ...circle,
                turkishText: template[circleType].turkishText,
                arabicText: template[circleType].arabicText,
            })
        } else {
            setCircle({
                ...circle,
                turkishText: '',
                arabicText: '',
                englishText: '',
            })
        }
    }, [circleType])

    const handleChange = (event) => {
        if (event.target.type === 'checkbox') {
            setCircle({
                ...circle,
                [event.target.name]: event.target.checked,
            })
        } else {
            setCircle({
                ...circle,
                [event.target.name]: event.target.value,
            })
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        const id = circle.id || generateID(6)

        const payload = {
            ...circle,
            isHatim: circleType === 'kuranhatim',
            goal: parseInt(circle.goal),
            counter: 0,
            id,
        }
        try {
            await createCircleApi(payload)
            splitbee.track(SplitbeeEvents.circleCreate, {
                title: payload.title,
                id,
            })
            if (isAdminView) {
                await addCircleToPublic(id)
            }

            if (isMobile) {
                console.log(id)
                window.parent.postMessage(
                    JSON.stringify({
                        action: 'VIEW_PAGE',
                        id,
                    }),
                    '*'
                )
            } else {
                setCircle(initCircleObj)
                navigate(`/${id}`)
            }
        } catch (e) {
            console.log({ e })
        }
    }

    return (
        <>
            {!isMobile && (
                <h1 className="text-2xl font-bold mb-5 dark:text-gray-300">
                    {isAdminView && 'Herkese Acik'} {t('createCircle')}
                </h1>
            )}
            <form onSubmit={onSubmit}>
                <input
                    className="form-field mb-4"
                    name="title"
                    placeholder={t('title')}
                    onChange={handleChange}
                    value={circle.title}
                    type="text"
                    required
                />
                 <select
                    className="form-field mb-4"
                    onChange={handleDuaSelectorChange}
                >
                    <option value="" disabled selected>
                        {t('dua')}
                    </option>
                    <option value="kuranhatim">{t('kuranHatim')}</option>
                    <option value="custom">{t('createYourDua')}</option>
                    <option value="salavat">{t('salawat')}</option>
                    <option value="sifa">{t('healingSalawat')}</option>
                    <option value="tefriciye">{t('tefriciye')}</option>
                    <option value="tevbe">{t('istigfar')}</option>
                </select>

                {circleType === "kuranhatim" && <select
                    className="form-field mb-4"
                    onChange={handleQuranTypeChange}
                    value={quranType}
                    defaultValue={quranType}
                >
                    <option value="cuz">Cüz Olarak Dağıt</option>
                    <option value="sayfa">Sayfa Olarak Dağıt</option>
                </select>}
                {circleType !== 'kuranhatim' && <input
                    className="form-field mb-4"
                    placeholder={t('count')}
                    name="goal"
                    onChange={handleChange}
                    value={circle.goal}
                    type="number"
                    required
                />}
                <textarea
                    className="form-field mb-4"
                    name="description"
                    placeholder={t('description')}
                    value={circle.description}
                    onChange={handleChange}
                    rows={4}
                />
                {isAdminView && (
                    <>
                        <input
                            className="form-field mb-4"
                            name="enTitle"
                            placeholder={'English Title'}
                            onChange={handleChange}
                            value={circle.enTitle}
                            type="text"
                        />
                        <textarea
                            className="form-field mb-4"
                            name="enDescription"
                            placeholder={'English Description'}
                            onChange={handleChange}
                            value={circle.enDescription}
                            type="text"
                        />
                        <textarea
                            className="form-field mb-4"
                            name="enTranslation"
                            placeholder={'English Translation'}
                            onChange={handleChange}
                            value={circle.enTranslation}
                            type="text"
                        />
                    </>
                )}


                {circleType === 'custom' && (
                    <>
                        <textarea
                            className="form-field mb-4"
                            name="arabicText"
                            value={circle.arabicText}
                            onChange={handleChange}
                            placeholder={t('arabicVersion')}
                            rows={4}
                        />
                        <textarea
                            className="form-field mb-4"
                            name="turkishText"
                            value={circle.turkishText}
                            onChange={handleChange}
                            placeholder={t('translation')}
                            rows={4}
                        />
                    </>
                )}

                <label className="relative inline-flex cursor-pointer mb-4">
                    <input
                        type="checkbox"
                        checked={circle.isLoop}
                        name="isLoop"
                        className="sr-only peer"
                        onChange={handleChange}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-herkul-500"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                        {t('toggleCircle')}
                    </span>
                </label>
                {isAdminView && (
                    <input
                        className="form-field mb-4"
                        name="id"
                        placeholder="Custom ID"
                        onChange={handleChange}
                        value={circle.id}
                        type="text"
                    />
                )}

                <button className="btn">{t('create')}</button>
            </form>
        </>
    )
}

export default CreateCircle
